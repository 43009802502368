<div class="overflow-x-hidden">
  <h3 class="mb-2">Search and select a labor order to associate to the timesheet</h3>
  <div>
    <form>
      <app-text-input [placeholder]="'Search Labor Order'"
                      [label]="'Search Labor Order'"
                      [required]="true"
                      [formControl]="searchOrderControl"
                      class="block mb-4" />

      <div class="mt-4 border border-gray-200 p-1">
        <span><b>Selected Labor Order: </b></span>
        <span>{{ selectedLaborOrder ? selectedLaborOrder.referenceId + ' - ' + selectedLaborOrder.name : 'none' }}</span>
      </div>
      <div class="mb-4 border border-gray-200 p-1">
        <span><b>Current Labor Order: </b></span>
        <span>{{ data.laborOrder ? data.laborOrder.referenceId + ' - ' + data.laborOrder.name : 'none' }}</span>
      </div>

      @if (isLoading) {
        <div class="w-full">
          <mat-spinner class="mx-auto" [diameter]="20"></mat-spinner>
        </div>
      } @else if (!laborOrders?.length) {
        <div class="w-full">
          <span class="text-center py-8">There are currently no labor orders to show.</span>
        </div>
      } @else if (laborOrders) {
        @for (order of laborOrders; track $index; let odd = $odd) {
          <div 
            [ngClass]="{ 'bg-gray-200': odd }"
            (click)="selectedLaborOrder = order"
            class="hover:bg-blue-shade cursor-pointer border border-gray-200 p-1 flex justify-between">
            <span>{{ order.referenceId }} - {{ order.name }}</span>
            <span>{{ order.startDate | momentFormat:'complete' }} </span>
          </div>
        }
      }
    </form>
  </div>
  <div>
    <app-dialog-footer [isSaving]="isSaving"
                       [loadingText]="'Saving...'"
                       [submitActionName]="'Confirm'"
                       [valid]="selectedLaborOrder"
                       (submitAction)="associate()"
                       (cancelAction)="close()" />
  </div>
</div>
