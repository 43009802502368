import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { API_URL } from "src/environments/settings";
import { LaborOrder } from "../../../core/interfaces/labor-order.interface";
import { TimeSheet } from "../../../core/interfaces/timesheet.interface";
import { EnvironmentService } from "../../../core/services/environment.service";
import { getParamsFromFilters } from "../../../shared/utils/utils";
import { ModalSelectLaborOrderComponent } from "../components/modal-select-labor-order/modal-select-labor-order.component";
import { TimeKeeper } from "../interfaces/timekeeper.interface";
import { TimeSheetRow } from "../interfaces/timesheet-row.model";
import { TimesheetsFilters } from "../interfaces/timesheets-filters.interface";
import { UpdateTimeSheetRowStatus } from "../interfaces/update.timesheetrow.status.model";

@Injectable({ providedIn: "root" })
export class TimesheetsService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private dialog: MatDialog,
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getTimeSheets(skipCount: number, maxResult: number, filters?: TimesheetsFilters) {
    if (this.env.isBrowser) {
      const params: HttpParams = new HttpParams({
        fromObject: {
          ...filters,
          Skipcount: skipCount,
          MaxResultCount: maxResult
        }
      });
      return this.http.get<any>(`${this.apiUrl}/app/timeSheet/timeSheetSimpleListWithFilters`, { params });
    }
  }

  getTimeSheetById(id: string) {
    if (this.env.isBrowser) return this.http.get<any>(`${this.apiUrl}/app/timeSheet/${id}`);
  }

  getMyEmployers() {
    return this.http.get<any>(`${this.apiUrl}/app/permissionAdministrator/current`);
  }

  update(timesheet: TimeSheet) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/${timesheet.id}`, timesheet);
  }

  create(timesheet: TimeSheet) {
    return this.http.post<any>(`${this.apiUrl}/app/timeSheet`, timesheet);
  }

  remove(timesheetId: string, comments: string) {
    return this.http.delete(`${this.apiUrl}/app/timeSheet/${timesheetId}/withComments?deletionComments=${comments}`).toPromise();
  }

  recover(timesheetId: string) {
    return this.http.post(`${this.apiUrl}/app/timeSheet/${timesheetId}/recover`, {});
  }

  removeRow(timeSheetId: string, rowIds: string[]) {
    const queryParams: string = rowIds.map((e) => `ids=${e}`).join('&');
    return this.http.delete(`${this.apiUrl}/app/timeSheet/rowDelete/${timeSheetId}?${queryParams}`);
  }

  updateOrCreateTimeSheetRows(timeSheetId: string, timeSheetsRows: TimeSheetRow[]) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/rowsUpdateOrCreate/${timeSheetId}`, timeSheetsRows);
  }

  getTimeSheetDetails(id: string) {
    return this.http.get<any>(`${this.apiUrl}/app/timeSheet/${id}/timeSheetDetail`);
  }

  setTimeSheetApproved(timeSheetId: string) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimesheetApproved/${timeSheetId}`, null);
  }

  setTimeSheetPendingApproval(timeSheetId: string) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimeSheetPendingApproval/${timeSheetId}`, null);
  }

  setTimeSheetPendingReapproval(timeSheetId: string, reason: string) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimeSheetPendingReapproval/${timeSheetId}`, { reason });
  }

  setTimeSheetRejected(timeSheetId: string, reason: string) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimeSheetRejected/${timeSheetId}`, { reason });
  }

  setTimeSheetRowRejected(timeSheetRowId: string, updateModel: UpdateTimeSheetRowStatus) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimeSheetRowRejected/${timeSheetRowId}`, updateModel);
  }

  setTimeSheetRowDraft(timeSheetRowId: string, updateModel: UpdateTimeSheetRowStatus) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimeSheetRowDraft/${timeSheetRowId}`, updateModel);
  }

  getTimeSheetActivity(timeSheetId: string) {
    if (this.env.isBrowser)
      return this.http.get<any>(`${this.apiUrl}/app/timeSheet/${timeSheetId}/activityAuditEvents`);
  }

  getTimeSheetsGroupedByLaborOrder(skipCount: number, maxResult: number, filters?: TimesheetsFilters) {
    const filterParams = getParamsFromFilters(filters);
    if (this.env.isBrowser)
      return this.http.get<any>(
        `${this.apiUrl}/app/timeSheet/groupedByLabor?MaxResultCount=${maxResult}&Skipcount=${skipCount}${filterParams}`
      );
  }

  getLaborOrderWithTimeSheets(id: string) {
    if (this.env.isBrowser)
      return this.http.get<any>(`${this.apiUrl}/app/laborOrder/${id}/laborWithTimesheets`);
  }

  checkTimeSheetError(timeSheetId: string) {
    if (this.env.isBrowser)
      return this.http.get<any>(`${this.apiUrl}/app/timeSheet/${timeSheetId}/errorsCheck`);
  }

  adjustTimeSheet(timeSheetId: string) {
    return this.http.post<any>(`${this.apiUrl}/app/timeSheet/${timeSheetId}/adjustment`, null);
  }

  setTimeSheetUnderReview(timeSheetId: string, reason: string = null) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimeSheetUnderReview/${timeSheetId}`, { reason });
  }

  duplicateTimeSheet(timeSheetId: string) {
    return this.http.post<any>(`${this.apiUrl}/app/timeSheet/${timeSheetId}/duplicate`, null);
  }

  setTimeSheetListToPendingApproval(timeSheetIds: string[]) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimesheetListToPendingApproval`, timeSheetIds);
  }

  setTimeSheetListToUnderReview(timeSheetIds: string[]) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimesheetListToUnderReview`, timeSheetIds);
  }

  setTimeSheetListToApproved(timeSheetIds: string[]) {
    return this.http.put<any>(`${this.apiUrl}/app/timeSheet/setTimesheetListToApproved`, timeSheetIds);
  }
  
  associateTimesheetToLaborOrder(timesheetId: string, laborOrderId: string) {
    return this.http.post<any>(`${this.apiUrl}/app/timeSheet/associateTimeSheet?timeSheetId=${timesheetId}&laborOrderId=${laborOrderId}`, {});
  }

  openModalAssociateTimesheetAndLaborOrder(timesheetId: string, laborOrder: LaborOrder, fromList: boolean): void {
    this.dialog.open(ModalSelectLaborOrderComponent, {
      width: "600px",
      autoFocus: false,
      data: {
        timesheetId,
        laborOrder,
        fromList
      }
    });
  }

  getTimekeepers(): Observable<TimeKeeper[]> {
    return this.http.get<TimeKeeper[]>(`${this.apiUrl}/app/timeSheet/timeKeeperList`);
  }
}
